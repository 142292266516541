import {FC} from "react";
import {A11yButton} from "components/common/buttons/A11yButton";
import {getCreditCardBrandIcon} from "utils/payment";
import {EN_LOCALE} from "locales/en";
import {Chevron} from "assets/images";
import styles from "./PaymentMethodButton.module.scss";

export const PaymentMethodButton: FC<{
  paymentMethodLast4Symbols: string;
  paymentMethodBrand: string;
  toggleUpdatePayment: () => void;
}> = ({paymentMethodLast4Symbols, paymentMethodBrand, toggleUpdatePayment}) => {
  return (
    <A11yButton onAction={toggleUpdatePayment} className={styles.paymentMethodContainer}>
      <div className={styles.title}>
        <p>{EN_LOCALE.label.payment}</p>
      </div>
      <div className={styles.cardDetails}>
        <div className={styles.card}>
          <img
            src={getCreditCardBrandIcon(paymentMethodBrand)}
            width={34}
            height={22}
            alt={EN_LOCALE.label.creditCard}
            className={styles.cardIcon}
          />

          <span>
            {paymentMethodBrand} •••• {paymentMethodLast4Symbols}
          </span>
        </div>
        <img src={Chevron} alt={EN_LOCALE.label.chevron} className={styles.chevron} />
      </div>
    </A11yButton>
  );
};
