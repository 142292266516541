import {createSelector} from "reselect";
import {FETCHING_STATUS} from "constants/api";
import {type ICustomerInitialData} from "../types/initialData";
import {getCustomerData} from "./index";

export const getAddressForDrawer = createSelector(
  getCustomerData,
  (state: ICustomerInitialData) => {
    if (state.addresses.addressIdToUpdate) {
      const addressForDrawer = state.addresses.savedAddresses.find(
        ({id}) => id === state.addresses.addressIdToUpdate
      );
      return {isSavedAddress: true, address: addressForDrawer};
    }

    // for unauth user and suggested addresses
    return {isSavedAddress: false, address: state.addresses.addressToCreate};
  }
);

export const getIsPuttingAddress = createSelector(
  getCustomerData,
  (state: ICustomerInitialData) =>
    state.apiRequests.putAddress.fetchingStatus === FETCHING_STATUS.PENDING
);

export const getAddressToCreate = createSelector(
  getCustomerData,
  (state: ICustomerInitialData) => state.addresses.addressToCreate
);
