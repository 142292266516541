import {FC} from "react";
import CheckCircle from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {Checkbox, FormControlLabel, TextField} from "@mui/material";
import {Button} from "components/common/buttons/Button";
import {Drawer} from "components/common/drawers/Drawer";
import {useAddressDrawer} from "hooks/orderBuilder/addresses/useAddressDrawer";
import {useAppSelector} from "state/redux/hooks";
import {getIsAddressDetailsDrawerOpened} from "state/redux/slices/order/selectors/addressesSelectors";
import {
  SUBTITLE_TEXT,
  PLACE_HOLDER,
  LABEL_TEXT,
  NEW_ADDRESS,
  CHECKBOX_LABEL,
  SAVE_EDITED_ADDRESS,
} from "constants/order";
import {EN_LOCALE} from "locales/en";
import styles from "./addressDetailsDrawer.module.scss";

export const AddressDetailsDrawer: FC<{
  onSave?: (data: {instructions: string; leaveAtDoor: boolean; address2: string}) => void;
}> = ({onSave}) => {
  const isDrawerOpened = useAppSelector(getIsAddressDetailsDrawerOpened);
  const {
    address,
    isSavedAddress,
    isPuttingAddress,
    isFetchingStore,
    leaveAtDoor,
    currentAddressString,
    onInstructionChange,
    toggleLeaveAtDoor,
    onAddressDetailsChange,
    onAddressSave,
    closeDrawer,
  } = useAddressDrawer({onSave});

  const isLoading = isFetchingStore || isPuttingAddress;

  return (
    <Drawer
      isShowingDrawer={isDrawerOpened}
      closeDrawer={closeDrawer}
      title={EN_LOCALE.label.addressDetails}
    >
      <div className={styles.addressDetailsContainer}>
        <div className={styles.addressDetailsContent}>
          <h2 className={styles.subTitle}>{SUBTITLE_TEXT.ADDRESS_DETAILS}</h2>
          <span className={styles.address}>{currentAddressString}</span>
          <TextField
            fullWidth
            color="primary"
            placeholder={PLACE_HOLDER.ADDRESS_DETAILS}
            label={LABEL_TEXT.ADDRESS_DETAILS}
            maxRows={1}
            defaultValue={address?.address2 || ""}
            onChange={onAddressDetailsChange}
          />
          <h2 className={styles.subTitle}>{SUBTITLE_TEXT.PICKUP_INSTRUCTIONS}</h2>
          <TextField
            fullWidth
            color="primary"
            placeholder={PLACE_HOLDER.PICKUP_INSTRUCTIONS}
            label={LABEL_TEXT.PICKUP_INSTRUCTIONS}
            minRows={5}
            defaultValue={address?.instructions || ""}
            multiline
            onChange={onInstructionChange}
            inputProps={{maxLength: 255}}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon style={{fill: "var(--theme-color)"}} />}
                checkedIcon={<CheckCircle style={{fill: "var(--theme-color)"}} />}
                color="primary"
                onChange={toggleLeaveAtDoor}
                checked={leaveAtDoor}
              />
            }
            classes={{label: styles.label, root: styles.checkbox}}
            label={CHECKBOX_LABEL}
          />
        </div>
        <Button onClick={onAddressSave} disabled={isLoading} fullWidth={true}>
          {isSavedAddress ? SAVE_EDITED_ADDRESS : NEW_ADDRESS}
        </Button>
      </div>
    </Drawer>
  );
};
