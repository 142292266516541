import {createAsyncThunk} from "@reduxjs/toolkit";
import {
  ONLINE_ORDER_REDUCER,
  FETCH_SERVICES_PRICES,
} from "state/redux/slices/order/constants/general";
import {ServiceTarget} from "utils/formatters/formatServiceTarget";
import {fetchFeaturedServices} from "api/online-order";
import {modifierPricingTypes} from "constants/order";
import {ObjectValues} from "types/typeUtils";
import {type IProductPrice, type IServicePrice} from "../types/services";

export interface IStoreServicesPrices {
  dryCleaning: IServicePrice[];
  laundry: IServicePrice[];
  products: IProductPrice[];
  services: [];
  success: boolean;
}

export const fetchStoreServicesPrices = createAsyncThunk(
  `${ONLINE_ORDER_REDUCER}/${FETCH_SERVICES_PRICES}`,
  async (
    {
      storeId,
      ...params
    }: {
      storeId: number | string;
      type: ObjectValues<typeof modifierPricingTypes>;
    } & ServiceTarget,
    thunkApi
  ) => {
    try {
      const response = await fetchFeaturedServices(storeId, params);
      return response.data as IStoreServicesPrices;
    } catch (error: any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);
