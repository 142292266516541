import {FC} from "react";
import {DELIVERY_PROVIDERS, SCHEDULE_TABS} from "constants/order";
import {EN_LOCALE} from "locales/en";
import {ObjectValues} from "types/typeUtils";
import styles from "./providerLabel.module.scss";

export const ProviderLabel: FC<{
  provider: ObjectValues<typeof DELIVERY_PROVIDERS>;
  onDemandProviderAssets: {
    providerName: string;
    providerLogo: string;
  } | null;
  price: string | null;
}> = ({provider, onDemandProviderAssets, price}) => {
  return (
    <div className={styles.providerLabelWrapper}>
      {provider === DELIVERY_PROVIDERS.onDemand ? (
        // in some cases back-end can have error from On Demand provider
        // and in this case we don't have any details about delivery provider
        // so we don't show any provider name or logo
        !!onDemandProviderAssets && (
          <div className={styles.onDemandProviderWrapper}>
            <img
              width={16}
              height={16}
              src={onDemandProviderAssets.providerLogo}
              alt={EN_LOCALE.label.transportationProviderLogo}
            />
            <span className={styles.providerName}>
              {onDemandProviderAssets.providerName}
            </span>
          </div>
        )
      ) : (
        <span className={styles.providerName}>{SCHEDULE_TABS.ourDrivers}</span>
      )}
      {/* TBD for cases, when On Demand provider estimation is unsuccessful */}
      <span className={styles.price}>{price || EN_LOCALE.label.TBD}</span>
    </div>
  );
};
