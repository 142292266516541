import {useCallback} from "react";
import {useFullStoryEvent} from "hooks/fullstory/useFullStoryEvent";
import {useAppDispatch} from "state/redux/hooks";
import {orderThunks} from "state/redux/slices/order";
import {type INearStoresParams} from "state/redux/slices/order/types/thunkParams";
import {
  ATTENDED_ADDRESS_ESTIMATED,
  SAVED_ADDRESS_ESTIMATION_STARTED,
  NEW_ADDRESS_ESTIMATION_STARTED,
  UNATTENDED_ADDRESS_ESTIMATED,
} from "constants/fullStory/events/delivery";

export const useGetAddressEstimate = () => {
  const dispatch = useAppDispatch();
  const {submitEvent} = useFullStoryEvent();

  const estimateAddress = useCallback(
    async (estimateParams: INearStoresParams) => {
      submitEvent(
        estimateParams?.id
          ? SAVED_ADDRESS_ESTIMATION_STARTED
          : NEW_ADDRESS_ESTIMATION_STARTED
      );
      const estimationStartTime = new Date().valueOf();
      const result = await dispatch(orderThunks.getNearStores(estimateParams)).unwrap();
      const estimationEndTime = new Date().valueOf();
      const estimationTime = estimationEndTime - estimationStartTime;
      if (result?.ownDeliveryIsAvailable || result?.onDemandIsAvailable) {
        submitEvent(ATTENDED_ADDRESS_ESTIMATED, {
          ownDeliveryIsAvailable: result?.ownDeliveryIsAvailable,
          ownDeliveryFee: result?.ownDeliveryIsAvailable
            ? result?.ownDeliveryStore?.deliveryFeeInCents / 2
            : null,
          onDemandIsAvailable: result?.onDemandIsAvailable,
          onDemandDeliveryFee: result?.onDemandIsAvailable
            ? result?.onDemandDeliveryStore?.deliveryFeeInCents -
              result?.onDemandDeliveryStore?.subsidyInCents
            : null,
          onDemandProviderName: result?.onDemandIsAvailable
            ? result?.onDemandProviderDetails?.providerName
            : null,
          estimationTime,
        });
      } else {
        submitEvent(UNATTENDED_ADDRESS_ESTIMATED, {estimationTime});
      }

      return result;
    },
    [dispatch, submitEvent]
  );

  return {estimateAddress};
};
