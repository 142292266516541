import {useCallback} from "react";
import {useOrderConfig} from "hooks/newOrder/useOrderConfig";
import {useGetAddressEstimate} from "hooks/orderBuilder/addresses/useGetAddressEstimate";
import {useAppSelector} from "state/redux/hooks";
import {orderSelectors} from "state/redux/slices/order";
import {getAddressToEstimate} from "state/redux/slices/order/selectors/addressesSelectors";
import {FETCHING_STATUS} from "constants/api";

export const useStoreToService = () => {
  const addressToEstimate = useAppSelector(getAddressToEstimate);
  const {
    fetchingStatus: businessDataStatus,
    data: {businessId},
  } = useAppSelector(orderSelectors.getOrderInitialData);
  const storeConfiguration = useAppSelector(orderSelectors.getNearStoresData);
  const {estimateAddress} = useGetAddressEstimate();
  const {isPageJustOpened} = useOrderConfig();

  const getStoreToService = useCallback(async () => {
    if (addressToEstimate?.googlePlacesId && isPageJustOpened) {
      await estimateAddress({
        ...addressToEstimate,
        businessId: Number(businessId),
        zipCode: String(addressToEstimate?.postalCode),
      });
    }
  }, [addressToEstimate, estimateAddress, businessId, isPageJustOpened]);

  const {fetchingStatus: storeToServiceStatus} = storeConfiguration;

  const isAvailableForStoreFetching =
    businessDataStatus === FETCHING_STATUS.FULFILLED &&
    storeToServiceStatus === FETCHING_STATUS.INITIAL &&
    addressToEstimate?.id &&
    businessId;

  return {
    store: storeConfiguration,
    getStoreToService,
    isAvailableForStoreFetching,
  };
};
