import {useState, ChangeEvent} from "react";
import {TextField} from "@material-ui/core";
import {toast} from "react-toastify";
import {Flex, Text, Button} from "rebass/styled-components";
import {DockModal, ToastError} from "components/common";
import {tipSelectionStyles} from "components/common/TipSelection/styles";
import theme from "constants/themes/theme";
import styles from "./styles";

interface ICustomAmountProps {
  addNewAmount: (amount: number) => void;
  showModal: boolean;
  toggleShowModal: (status: boolean) => void;
}

const AVAILABLE_CREDIT_MIN_AMOUNT = 5;
const EXCEPT_DIGITS_POINT_REGEX = /[^0-9.]/g;

const CustomAmount = ({addNewAmount, showModal, toggleShowModal}: ICustomAmountProps) => {
  const [amountState, setAmountState] = useState<string>();
  const monetaryClasses = tipSelectionStyles();

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const splittedValues = event.target.value.split(".");
    if (splittedValues[1]?.length > 2) {
      splittedValues[1] = splittedValues[1].substring(0, 2);
    }

    if (Number(splittedValues.join(".")) < 1000) {
      setAmountState(splittedValues.join("."));
    }
  };

  const handleInputBlur = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newInput = String(
      Number(event.target.value.replace(EXCEPT_DIGITS_POINT_REGEX, "")).toFixed(2)
    );

    setAmountState(newInput);
  };

  const closeModal = () => {
    setAmountState("");
    toggleShowModal(!showModal);
  };

  const sendNewAmountCredit = () => {
    if (amountState) {
      if (Number(amountState) < AVAILABLE_CREDIT_MIN_AMOUNT) {
        toast.error(
          <ToastError
            message={`Your balance cannot be less than $${AVAILABLE_CREDIT_MIN_AMOUNT.toFixed(
              2
            )}.`}
          />
        );
        return;
      }

      addNewAmount(Number(amountState));
      closeModal();
    }
  };

  return (
    <DockModal
      header="Custom Amount"
      isOpen={showModal}
      toggle={() => {
        closeModal();
      }}
    >
      <Flex sx={styles.wrapperModal}>
        <Flex sx={styles.wrapperContent}>
          <Text sx={styles.normalText}>
            Please enter the custom amount you would like to add to your credit balance.{" "}
            <Text sx={styles.boldText}>
              Minimum ${AVAILABLE_CREDIT_MIN_AMOUNT.toFixed(2)}
            </Text>
            .
          </Text>
          <TextField
            className={monetaryClasses.inputTypeNum}
            color="primary"
            variant="outlined"
            fullWidth
            autoFocus
            label="Custom Amount"
            type="number"
            value={amountState}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            InputProps={{
              lang: "en-US",
              startAdornment: <div className={monetaryClasses.inputAdornment}>$</div>,
            }}
            inputProps={{
              style: {
                color: theme.colors.NEW_TEXT_PRIMARY_BLUE,
                fontSize: 18,
                lineHeight: "125%",
                fontWeight: 400,
              },
              lang: "en-US",
              inputMode: "decimal",
              pattern: "[0-9]{0,3}([.][0-9]{1,2})?",
            }}
          />
        </Flex>
        <Button onClick={sendNewAmountCredit} sx={styles.saveButton}>
          Save
        </Button>
      </Flex>
    </DockModal>
  );
};

export default CustomAmount;
