import {FC} from "react";
import cn from "classnames";
import {useFlags} from "launchdarkly-react-client-sdk";
import {SectionActionButton} from "components/common/buttons/SectionActionButton";
import {AddressDetailsDrawer} from "components/common/drawers/AddressDetailsDrawer";
import {useAppDispatch} from "state/redux/hooks";
import {customerActions} from "state/redux/slices/customer";
import {orderActions} from "state/redux/slices/order";
import {EN_LOCALE} from "locales/en";
import {EditIcon} from "assets/images";
import styles from "./addressDetails.module.scss";

export const AddressDetails: FC<{
  addressId?: number;
  addressDetails: string | null;
  instructions: string | null;
  onSubmit?: (data: {
    instructions: string;
    address2: string;
    leaveAtDoor: boolean;
  }) => void;
  onDrawerOpen?: () => void;
}> = ({addressId, addressDetails, instructions, onSubmit, onDrawerOpen}) => {
  const {manageOrderV2} = useFlags();

  const dispatch = useAppDispatch();
  const openAddressDetailsDrawer = () => {
    onDrawerOpen?.();
    if (addressId) {
      dispatch(customerActions.setAddressIdToUpdate(addressId));
    }
    dispatch(orderActions.setIsAddressDetailsDrawerOpened(true));
  };

  return (
    <>
      <div className={styles.addressDetailsWrapper}>
        <button onClick={openAddressDetailsDrawer} className={styles.button}>
          <div>
            <h3>Address Details</h3>
            {manageOrderV2 ? (
              <SectionActionButton variant="primary" />
            ) : (
              <img src={EditIcon} alt={EN_LOCALE.label.pencil} width={16} height={16} />
            )}
          </div>
          <p
            className={cn({
              [styles.empty]: !addressDetails,
            })}
          >
            <span>Apt / Suite / Unit</span> {addressDetails}
          </p>
          <p
            className={cn({
              [styles.empty]: !instructions,
            })}
          >
            <span>Pickup Instructions</span>
            <br />
            {instructions}
          </p>
        </button>
        <AddressDetailsDrawer onSave={onSubmit} />
      </div>
    </>
  );
};
