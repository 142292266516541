import {useState} from "react";
import {TextField} from "@material-ui/core";
import {Box, Flex, Text, Button} from "rebass/styled-components";
// Components
import {Drawer} from "components/common/drawers/Drawer";
import {onDemandDeliveryTypes} from "components/online-order/constants";
import {orderThemeColors} from "components/online-order/orderTheme";
import {useBranding} from "hooks/branding/useBranding";
// Hooks
import useWindowSize from "hooks/useWindowSize";
// Assets & Styles
import {BlueCar} from "assets/images";

const AddOnDemandDeliveryTip = (props) => {
  const {
    isNewOrder,
    onAddDeliveryTip,
    deliveryTipFor,
    showDeliveryTipModal,
    setShowDeliveryTipModal,
  } = props;
  const {primaryColor} = useBranding();

  const tipOptions = [
    ...(deliveryTipFor === onDemandDeliveryTypes.pickupAndDelivery
      ? [
          {display: "$6", value: 6},
          {display: "$10", value: 10},
          {display: "$14", value: 14},
        ]
      : [
          {display: "$3", value: 3},
          {display: "$5", value: 5},
          {display: "$7", value: 7},
        ]),
    {display: "Other", value: 0},
  ];

  const [newDeliveryTipAmount, setNewDeliveryTipAmount] = useState(
    deliveryTipFor === onDemandDeliveryTypes.pickupAndDelivery ? 10 : 5
  );
  const [newDeliveryTipObject, setNewDeliveryTipObject] = useState(tipOptions[1]);
  const [width] = useWindowSize();
  const [showMainTipOptions, setShowMainTipOptions] = useState(true);
  const [showCustomTipOption, setShowCustomTipOption] = useState(false);
  const [header, setHeader] = useState();

  /**
   * Assign the incoming event object data to the newDeliveryTip state value
   *
   * @param {Object} tip
   */
  const handleDeliveryTipChange = (tip) => {
    setNewDeliveryTipAmount(tip.value);
    setNewDeliveryTipObject(tip);
    if (tip.display === "Other") {
      setHeader("Custom Tip");
      setShowCustomTipOption(true);
      setShowMainTipOptions(false);
    }
  };

  /**
   * Assign the incoming tip amount to the newDeliveryTipAmount
   *
   * @param {Number} event
   */
  const handleCustomTipAmountChange = (event) => {
    setNewDeliveryTipAmount(event.target.value);
  };

  const getHeaderLabel = () => {
    return deliveryTipFor === onDemandDeliveryTypes.pickupAndDelivery
      ? "On-Demand Pickup & Delivery"
      : deliveryTipFor === onDemandDeliveryTypes.pickup
        ? "On-Demand Pickup"
        : "On-Demand Delivery";
  };

  /**
   * Display the tip box selections
   */
  const renderTipPills = () => {
    return (
      <Flex {...styles.pillsContainer} py={width > 320 ? "10px" : ""}>
        <Flex flexDirection="row">
          {tipOptions.map((tip) => (
            <Flex
              key={tip.display}
              paddingRight={width < 320 ? "6px" : "12px"}
              flexDirection="row"
            >
              <Flex
                fontSize={width < 320 ? "12px" : "14px"}
                fontWeight={600}
                sx={{
                  ...styles.tipPillButton,
                  borderColor:
                    tip.display === newDeliveryTipObject.display
                      ? primaryColor
                      : orderThemeColors.textMain,
                  backgroundColor:
                    tip.display === newDeliveryTipObject.display
                      ? primaryColor
                      : "#FFFFFF",
                  color:
                    tip.display === newDeliveryTipObject.display
                      ? "#FFFFFF"
                      : orderThemeColors.textMain,
                }}
                onClick={() => {
                  handleDeliveryTipChange(tip);
                }}
              >
                <Text>{tip.display}</Text>
              </Flex>
            </Flex>
          ))}
        </Flex>

        <Text color={orderThemeColors.textMain} fontWeight={600}>
          ${Number(newDeliveryTipAmount).toFixed(2)}
        </Text>
      </Flex>
    );
  };

  /**
   * Render the main tip selection screen
   */
  const renderMainTipScreen = () => {
    return (
      <>
        <Flex flexDirection="row ">
          <Text {...styles.pickupOption}>{getHeaderLabel()}</Text>
        </Flex>
        <Flex flexDirection="row" justifyContent="space-between">
          <Flex flexDirection="column" pr="6px" width="100%">
            <Text {...styles.tipHeader} color={primaryColor}>
              Tip your Driver
              {deliveryTipFor === onDemandDeliveryTypes.pickupAndDelivery ? "s" : ""}
            </Text>

            <Text {...styles.subtext}>
              This order utilizes third party couriers to pickup and deliver laundry on
              your schedule.
            </Text>
          </Flex>
          <Flex
            flexShrink="0"
            width={width < 320 ? "95px" : width >= 768 ? "308px" : "155px"}
          >
            <Flex width={width < 320 ? "95px" : "155px"} {...styles.imageContainer} />
          </Flex>
        </Flex>

        <Text {...styles.tipDisclaimer}>
          {deliveryTipFor === onDemandDeliveryTypes.pickupAndDelivery
            ? "This tip will be split evenly between both drivers."
            : "100% of this tip will go to your driver."}
        </Text>

        <Flex>{renderTipPills()}</Flex>
      </>
    );
  };

  /**
   * Render the custom tip option
   */
  const renderCustomTipScreen = () => {
    return (
      <Flex flexDirection="column" width="calc(100% - 20px)">
        <TextField
          InputProps={{startAdornment: "$"}}
          variant="outlined"
          fullWidth
          label="Custom Tip Amount"
          type="number"
          value={newDeliveryTipAmount}
          onChange={handleCustomTipAmountChange}
        />
      </Flex>
    );
  };

  const renderTipModal = () => {
    return (
      <Drawer
        isShowingDrawer={showDeliveryTipModal}
        closeDrawer={() => {
          setShowCustomTipOption(!showCustomTipOption);
          setShowMainTipOptions(true);
          setHeader(null);
        }}
        title={header}
      >
        <Box height={showMainTipOptions ? "100%" : "80%"} {...styles.container}>
          {showMainTipOptions && renderMainTipScreen()}
          {showCustomTipOption && renderCustomTipScreen()}
          <Flex {...styles.footerContainer}>
            <Button
              {...styles.saveButton}
              onClick={() => {
                if (showCustomTipOption) {
                  setHeader(null);
                  setShowMainTipOptions(true);
                  setShowCustomTipOption(false);
                } else {
                  onAddDeliveryTip(newDeliveryTipAmount);
                  setShowDeliveryTipModal(!showDeliveryTipModal);
                }
              }}
            >
              {showMainTipOptions
                ? `${isNewOrder ? "ADD" : "UPDATE"} DRIVER TIP AND SUBMIT`
                : "CONTINUE"}
            </Button>
          </Flex>
        </Box>
      </Drawer>
    );
  };

  return <Box>{renderTipModal()}</Box>;
};

const styles = {
  container: {
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: "20px",
    py: "18px",
    position: "relative",
  },
  tipOptions: {
    width: "100%",
  },
  pickupOption: {
    color: orderThemeColors.textMain,
    sx: {
      alignItems: "center",
      justifyContent: "flex-start",
      fontSize: ["14px", "16px"],
    },
    py: "6px",
  },
  tipHeader: {
    sx: {
      alignItems: "center",
      justifyContent: "flex-start",
    },
    fontSize: ["20", "24px"],
    pt: "12px",
    pb: "18px",
    fontWeight: "600",
  },
  subtext: {
    fontSize: ["14px", "16px"],
    color: orderThemeColors.textMain,
  },
  saveButton: {
    sx: {
      width: "100%",
    },
    p: "18px",
    fontWeight: "700",
    textTransform: "capitalize",
  },
  tipDisclaimer: {
    fontSize: ["13px", "14px"],
    color: "NEW_TEXT_GREY",
    pt: "18px",
    pb: "15px",
  },
  tipPillButton: {
    border: "solid",
    borderRadius: "5px",
    borderWidth: "1px",
    px: "12px",
    py: "6px",
  },
  imageContainer: {
    sx: {
      width: ["100%", "100%", "100%", "100%"],
      position: "relative",
      overflow: "hidden",
      backgroundImage: `url(${BlueCar})`,
      backgroundSize: ["cover", "cover", "cover", "cover"],
      flexDirection: "column",
      top: "10px",
    },
    height: "100px",
    py: ["0px", "0px", "55px", "55px"],
    pr: ["0px", "0px", "0px", "20px"],
  },
  column: {
    float: "left",
    padding: "10px",
  },

  doorDashLogo: {
    verticalAlign: "middle",
  },

  pillsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    pr: "18px",
    width: "100%",
  },
  footerContainer: {
    py: "18px",
    pr: "18px",
  },
};

export default AddOnDemandDeliveryTip;
