import {FC, useEffect} from "react";
import Divider from "components/common/Divider";
import {DryCleaningCategoryProps} from "components/common/forms/services/DryCleaningCategory/DryCleaningCategory";
import {LaundryCategoryFormProps} from "components/common/forms/services/LaundryCategory/LaundryCategoryForm";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {fetchCustomerInfoThunk} from "state/redux/slices/customer/thunks/fetchCustomerInfoThunk";
import {orderSelectors} from "state/redux/slices/order";
import {getIsDryCleaningAvailableForDelivery} from "state/redux/slices/order/selectors/servicesSelector";
import {getNewOrderStoreId} from "state/redux/slices/order/selectors/storeSelectors";
import {fetchDeliveryServices} from "state/redux/slices/order/thunks/fetchDeliveryServices";
import {formatServiceTarget} from "utils/formatters/formatServiceTarget";
import {DryCleaningCategory} from "../DryCleaningCategory";
import {LaundryCategoryForm} from "../LaundryCategory";

export type ServiceCategoriesProps = DryCleaningCategoryProps & LaundryCategoryFormProps;

export const ServiceCategories: FC<ServiceCategoriesProps> = ({
  dryCleaningTurnAround,
  isDryCleaningCategorySelected,
  onDryCleaningCategoryToggle,
  ...laundryCategoryFormProps
}) => {
  const dispatch = useAppDispatch();
  const isDryCleaningAvailableForDelivery = useAppSelector(
    getIsDryCleaningAvailableForDelivery
  );
  const currentAddress = useAppSelector(orderSelectors.getCurrentCustomerAddress);
  const postalCode = currentAddress?.postalCode;
  const storeId = useAppSelector(getNewOrderStoreId);

  useEffect(() => {
    type FetchDetails = (data: {storeId: string | number}) => void;
    const fetchServicesDetails: FetchDetails = async ({storeId}) => {
      dispatch(
        fetchDeliveryServices({
          storeId,
          ...formatServiceTarget(currentAddress),
        })
      );
      dispatch(fetchCustomerInfoThunk({storeId}));
    };

    if (storeId && postalCode) {
      fetchServicesDetails({storeId});
    }
  }, [dispatch, currentAddress, storeId]);

  return (
    <div>
      {isDryCleaningAvailableForDelivery && (
        <>
          <DryCleaningCategory
            dryCleaningTurnAround={dryCleaningTurnAround}
            isDryCleaningCategorySelected={isDryCleaningCategorySelected}
            onDryCleaningCategoryToggle={onDryCleaningCategoryToggle}
          />
          <Divider />
        </>
      )}
      <LaundryCategoryForm {...laundryCategoryFormProps} />
    </div>
  );
};
