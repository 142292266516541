import {FC} from "react";
import classNames from "classnames";
import {SectionItem} from "components/common/layouts/Section/SectionItem";
import {EN_LOCALE} from "locales/en";
import {IconRoutes} from "assets/images";
import styles from "./addressIndicator.module.scss";

export interface AddressIndicatorProps {
  address?: string;
  manageOrderV2?: boolean;
  onClick?: () => void;
}

export const AddressIndicator: FC<AddressIndicatorProps> = ({
  address,
  manageOrderV2,
  onClick,
}) => {
  return (
    <SectionItem
      title={EN_LOCALE.label.address}
      icon={
        <img
          src={IconRoutes}
          alt={EN_LOCALE.label.geolocationPin}
          width={17}
          height={25}
        />
      }
      id={classNames({[styles.tuneUpAddressIndicator]: manageOrderV2})}
      onAction={onClick}
      variant={manageOrderV2 ? "primary" : "underlined"}
      placeHolder={EN_LOCALE.label.addAddress}
    >
      {!!address && <p className={styles.address}>{address}</p>}
    </SectionItem>
  );
};
