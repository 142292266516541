import {FC} from "react";
import {EN_LOCALE} from "locales/en";
import styles from "./timeFrameLabel.module.scss";

export const TimeFrameLabel: FC<{
  turnAround: number | null;
  configuration: {date: string; startTime: string; endTime: string} | null;
}> = ({turnAround, configuration}) => {
  return configuration ? (
    <>
      <p className={styles.day}>{configuration.date}</p>
      <p className={styles.timeFrame}>
        {configuration.startTime}-{configuration.endTime}
      </p>
    </>
  ) : (
    <>
      <p className={styles.title}>{EN_LOCALE.label.scheduleLater}</p>
      <p className={styles.description}>{EN_LOCALE.label.textWhenReady(turnAround)}</p>
    </>
  );
};
