import {httpClient} from "api";
import {ServiceTarget} from "utils/formatters/formatServiceTarget";

export const fetchOnlineOrderServices = ({
  storeId,
  zipCode,
  lat,
  lng,
}: Omit<ServiceTarget, "zipCode"> & {
  storeId: number;
  zipCode?: string;
}) => {
  return httpClient({
    method: "GET",
    url: `/live-status/stores/${storeId}/online-order-services`,
    params: {zipCode, lat, lng},
  });
};
