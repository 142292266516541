import {useEffect, useState} from "react";
import {useStoreToService} from "components/orderCreation/serviceFirstFlow/home/hooks/useStoreToService";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {customerActions} from "state/redux/slices/customer";
import {
  getAddressForDrawer,
  getIsPuttingAddress,
} from "state/redux/slices/customer/selectors/addressSelectors";
import {orderActions} from "state/redux/slices/order";
import {FetchingStatus} from "types/common";
import {useAddressSaving} from "./useAddressSaving";

export const useAddressDrawer = ({
  onSave,
}: {
  onSave?: (data: {instructions: string; leaveAtDoor: boolean; address2: string}) => void;
} = {}) => {
  const dispatch = useAppDispatch();

  const {isSavedAddress, address} = useAppSelector(getAddressForDrawer);
  const isPuttingAddress = useAppSelector(getIsPuttingAddress);
  const {store} = useStoreToService();
  const {saveAddress: saveAddressForOrder} = useAddressSaving();

  const isFetchingStore = store.fetchingStatus === FetchingStatus.Pending;
  const currentAddressString = `${address?.address1}, ${address?.city}, ${address?.firstLevelSubdivisionCode} ${address?.postalCode}`;

  const [addressDetails, setAddressDetails] = useState(address?.address2 || "");
  const onAddressDetailsChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAddressDetails(event.target.value);
  };

  const [instructions, setInstructions] = useState(address?.instructions || "");
  const onInstructionChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInstructions(event.target.value);
  };

  const [leaveAtDoor, setLeaveAtDoor] = useState<boolean>(address?.leaveAtDoor ?? true);
  const toggleLeaveAtDoor = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setLeaveAtDoor(checked);
  };

  const onAddressSave = async () => {
    return onSave
      ? onSave({instructions, address2: addressDetails, leaveAtDoor})
      : saveAddressForOrder({instructions, address2: addressDetails, leaveAtDoor});
  };

  const closeDrawer = () => {
    dispatch(orderActions.setIsAddressDetailsDrawerOpened(false));
    dispatch(customerActions.setAddressIdToUpdate(null));
  };

  useEffect(() => {
    setAddressDetails(address?.address2 || "");
    setInstructions(address?.instructions || "");
    setLeaveAtDoor(address?.leaveAtDoor ?? true);
  }, [address?.address2, address?.instructions, address?.leaveAtDoor]);

  return {
    address,
    isSavedAddress,
    isPuttingAddress,
    isFetchingStore,
    leaveAtDoor,
    currentAddressString,
    onAddressDetailsChange,
    onInstructionChange,
    toggleLeaveAtDoor,
    onAddressSave,
    closeDrawer,
  };
};
