import {FC, ReactNode} from "react";
import {A11yButton} from "components/common/buttons/A11yButton";
import {A11yButtonProps} from "components/common/buttons/A11yButton/A11yButton";
import {SectionActionButton} from "components/common/buttons/SectionActionButton";
import styles from "./SectionItem.module.scss";

interface SectionItemProps extends Omit<A11yButtonProps, "children"> {
  title: string;
  children?: ReactNode;
  icon?: ReactNode;
  placeHolder?: string;
  variant?: "primary" | "underlined";
}

export const SectionItem: FC<SectionItemProps> = ({
  title,
  placeHolder,
  icon,
  children,
  onAction,
  variant = "underlined",
  ...otherProps
}) => {
  return (
    <A11yButton className={styles.actionItemButton} onAction={onAction} {...otherProps}>
      {icon}
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>{title}</h3>

          {!!onAction && (
            <SectionActionButton
              variant={variant ? "primary" : "underlined"}
              className={styles.chevron}
            />
          )}
        </div>
        {children ? children : <p className={styles.placeHolder}>{placeHolder}</p>}
      </div>
    </A11yButton>
  );
};
