import {FC, Fragment} from "react";
import {IPaymentMethod} from "types/customer";
import CardItem from "./CardItem";

interface CardsListProps {
  currentPaymentMethods: IPaymentMethod[];
  handleDeletePaymentMethod: (paymentMethodToken: IPaymentMethod) => void;
  handlePaymentMethodSelection: (paymentMethodToken: string) => void;
}

const CardsList: FC<CardsListProps> = ({
  currentPaymentMethods,
  handleDeletePaymentMethod,
  handlePaymentMethodSelection,
}) => {
  return (
    <Fragment>
      {currentPaymentMethods?.length ? (
        currentPaymentMethods
          ?.slice()
          ?.sort((a, b) => b?.id - a?.id)
          ?.map((paymentMethod: IPaymentMethod) => (
            <CardItem
              key={`${paymentMethod.paymentMethodToken}-${paymentMethod.id}`}
              paymentMethod={paymentMethod}
              onSelection={() =>
                handlePaymentMethodSelection(paymentMethod.paymentMethodToken)
              }
              onDelete={() => handleDeletePaymentMethod(paymentMethod)}
            />
          ))
      ) : (
        <p className="no-cards">There are no saved credit cards</p>
      )}
    </Fragment>
  );
};

export default CardsList;
