import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {getAddressForDrawer} from "state/redux/slices/customer/selectors/addressSelectors";
import {patchAddress} from "state/redux/slices/customer/thunks/patchAddress";
import {orderActions} from "state/redux/slices/order";
import {
  getBusinessId,
  getNewOrderStoreId,
} from "state/redux/slices/order/selectors/storeSelectors";
import {createErrorToast} from "utils/notifications/createErrorToast";
import {type IAddress} from "types/customer";

type SaveAddress = (data: {
  instructions: string;
  address2: string;
  leaveAtDoor: boolean;
}) => Promise<IAddress | void>;

export const useAddressSaving = () => {
  const dispatch = useAppDispatch();

  const {isSavedAddress, address} = useAppSelector(getAddressForDrawer);
  const storeId = useAppSelector(getNewOrderStoreId);
  const businessId = useAppSelector(getBusinessId);

  const isNewAddress = !isSavedAddress;

  const saveAddress: SaveAddress = async ({instructions, address2, leaveAtDoor}) => {
    if (address?.lat && address?.lng && storeId && businessId) {
      const {
        lat,
        lng,
        address1,
        city,
        firstLevelSubdivisionCode,
        postalCode,
        countryCode,
      } = address;

      const {centsCustomerAddress} = await dispatch(
        patchAddress({
          googlePlacesId: String(address.googlePlacesId),
          data: {
            address1,
            city,
            firstLevelSubdivisionCode,
            postalCode,
            countryCode,
            instructions,
            address2,
            leaveAtDoor,
            lat,
            lng,
          },
          isNew: isNewAddress,
          storeId,
          businessId,
        })
      ).unwrap();

      dispatch(orderActions.setAddressToEstimate(centsCustomerAddress));
      dispatch(orderActions.setIsAddressDetailsDrawerOpened(false));
      dispatch(orderActions.setIsAddressSelectionActive(false));

      return centsCustomerAddress;
    } else {
      createErrorToast({
        primaryMessage: "Sorry, your address is invalid",
        secondaryMessage: "Please, try refresh the page",
      });
    }
  };

  return {saveAddress};
};
