import {createSelector} from "reselect";
import {getOnlineOrderData} from "../selectors";

export const getIsAddressSelectionActive = createSelector(
  getOnlineOrderData,
  (state) => state.drawersStates.isAddressSelectionDrawerOpened
);

export const getAddressToEstimate = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.addresses.addressToEstimate
);

export const getAddressInput = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.addresses.addressInput
);

export const getIsAddressDetailsDrawerOpened = createSelector(
  getOnlineOrderData,
  (state) => state.drawersStates.isAddressDetailsDrawerOpened
);
