import PropTypes from "prop-types";
import {A11yButton} from "components/common/buttons/A11yButton";
import {EN_LOCALE} from "locales/en";
import {Chevron, SalesTagIcon} from "assets/images";
import "./styles.scss";

const ApplyPromoItem = ({promoName, onClick}) => {
  return (
    <A11yButton className="section-row outline-distant" onAction={onClick}>
      <div className="static-info">
        <img src={SalesTagIcon} alt={EN_LOCALE.label.promo} />
        <p>{EN_LOCALE.label.promo}</p>
      </div>
      <div className="promo-action">
        <p>{promoName}</p>
        <img src={Chevron} alt={EN_LOCALE.label.arrow} />
      </div>
    </A11yButton>
  );
};

ApplyPromoItem.propTypes = {
  promoName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ApplyPromoItem.defaultProps = {
  promoName: "",
  onClick: () => {},
};

export default ApplyPromoItem;
