import React, {FC, useRef, useState} from "react";
import {Switch} from "@material-ui/core";
import cx from "classnames";
import {DICTIONARY} from "components/account/AccountCarePreferences/dictionary";
import Divider from "components/common/Divider";
import {FormCheckbox} from "components/common/checkboxes/FormCheckbox";
import {Textarea} from "components/common/forms";
import {useFindCurrentBusinessCustomer} from "components/newOrder/hooks/useFindCurrentBusinessCustomer";
import {useAppDispatch} from "state/redux/hooks";
import {customerActions} from "state/redux/slices/customer";
import {createErrorToast} from "utils/notifications/createErrorToast";
import {patchIsPreferencePageShowing} from "api/online-order";
import {HANG_DRY_TOGGLE_ID} from "constants/formLabelId";
import {EN_LOCALE} from "locales/en";
import {AccountCarePreferencesItem} from "./AccountCarePreferencesItem";
import styles from "./accountCarePreferencesForm.module.scss";
import {
  IAccountCarePreferencesForm,
  IAccountCarePreferencesFormOutput,
  IPreferenceItem,
  IPreferencesOption,
  PreferenceType,
} from "./types";

export const AccountCarePreferencesForm: FC<IAccountCarePreferencesForm> = ({
  forwardedRef,
  onSubmit,
  initialData,
  updatePreferenceAtomic,
  showVisibilityCheckbox,
}) => {
  const defaultRef = useRef<HTMLFormElement>(null);
  const accountCarePreferencesFormRef = forwardedRef || defaultRef;
  const [isHangDryFieldVisible, setIsHangDryFieldVisible] = useState<boolean>(
    initialData?.hangDry?.isHangDrySelected
  );

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const formData = new FormData(
      accountCarePreferencesFormRef.current as HTMLFormElement
    );
    const data = Object.fromEntries(formData.entries());
    onSubmit?.({
      ...data,
      isHangDrySelected: isHangDryFieldVisible,
    } as Partial<IAccountCarePreferencesFormOutput>);
  };

  const updateCheckedView = (
    type: PreferenceType,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (type === PreferenceType.SINGLE) {
      const allInputs = document.querySelectorAll(`input[name="${e.target.name}"]`);
      allInputs.forEach((input) => {
        input.parentElement?.classList.remove("checked");
      });
    }
    e.target.parentElement?.classList.toggle("checked");
    updatePreferenceAtomic?.(e.target.name, e.target.value, initialData);
  };

  const {
    businessCustomer: {isPreferencePageShowing, id: businessCustomerId},
  } = useFindCurrentBusinessCustomer();

  const dispatch = useAppDispatch();
  const setIsPreferencePageShowing = async () => {
    try {
      const response = await patchIsPreferencePageShowing({
        businessCustomerId,
        isPreferencePageShowing: !isPreferencePageShowing,
      });
      if (response.data.success) {
        const {id, isPreferencePageShowing} = response.data.updatedBusinessCustomer;
        dispatch(
          customerActions.setIsPreferencePageShowing({
            businessCustomerId: id,
            isPreferencePageShowing,
          })
        );
      }
    } catch (e) {
      createErrorToast({primaryMessage: EN_LOCALE.messages.genericError});
    }
  };

  return (
    <form
      ref={accountCarePreferencesFormRef}
      onSubmit={onSubmitHandler}
      id={styles.accountCarePreferencesForm}
      action="javascript:void(0)"
    >
      <p className="form-header">Care preferences are applied to all orders</p>
      <Divider className="full-width-divider" />
      <input type="hidden" name="storeId" value={String(initialData.storeId)} />
      {initialData.enabled &&
        initialData.preferences.length > 0 &&
        initialData.preferences.map(
          ({id: itemId, fieldName, options, type}: IPreferenceItem) => (
            <>
              <fieldset key={`${fieldName}:${itemId}`} className={styles.fieldset}>
                <legend className="item-header">
                  <h3 className="field-title">{fieldName}</h3>
                  {type === PreferenceType.MULTI && <span>(Select all that apply)</span>}
                </legend>
                {options.map(
                  ({id, value, isDefault, selected, selectionId}: IPreferencesOption) => (
                    <AccountCarePreferencesItem
                      key={`preference-item:${id}`}
                      type={type}
                      id={id}
                      value={value}
                      isDefault={isDefault}
                      selected={selected}
                      selectionId={selectionId}
                      options={options}
                      itemId={itemId}
                      updateCheckedView={updateCheckedView}
                    />
                  )
                )}
              </fieldset>
              <Divider className="full-width-divider" />
            </>
          )
        )}
      {initialData.enabled && initialData.hangDry.enabled && (
        <>
          <div
            className={cx("hang-dry-settings", {
              "is-textarea-visible": isHangDryFieldVisible,
            })}
          >
            <div className="hang-dry-header">
              <h3 className="field-title">{DICTIONARY.HANG_DRY}</h3>
              <Switch
                key="hang-dry-toggle"
                aria-label={EN_LOCALE.label.hangDry}
                id={HANG_DRY_TOGGLE_ID}
                color="primary"
                size="medium"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  setIsHangDryFieldVisible(e.target.checked);
                }}
                value={isHangDryFieldVisible}
                checked={isHangDryFieldVisible}
              />
            </div>
            {isHangDryFieldVisible && (
              <>
                {!!initialData.hangDry.instructions && (
                  <p className="hang-dry-instructions-text">
                    {initialData.hangDry.instructions}
                  </p>
                )}
                <Textarea
                  name="hangDryCustomerInstructions"
                  placeholder={DICTIONARY.HANG_DRY_INSTRUCTIONS}
                  defaultValue={initialData.hangDry.hangDryCustomerInstructions}
                  activePlaceholder={DICTIONARY.HANG_DRY_INSTRUCTIONS_PLACEHOLDER}
                  className="care-preferences-form-textarea "
                  maxLength={255}
                />
              </>
            )}
          </div>
          <Divider className="full-width-divider" />
        </>
      )}
      <Textarea
        name="carePreferencesNote"
        title={DICTIONARY.PREFERENCES_NOTES}
        placeholder={DICTIONARY.CARE_PREFERENCES}
        defaultValue={initialData.customerNotes}
        activePlaceholder={DICTIONARY.PREFERENCES_NOTES_PLACEHOLDER}
        className="care-preferences-form-textarea "
      />
      {showVisibilityCheckbox && (
        <>
          <Divider className="full-width-divider" />
          <FormCheckbox
            checked={!isPreferencePageShowing}
            onChange={setIsPreferencePageShowing}
            classes={{label: styles.visibilityLabel, root: styles.visibilityOption}}
            checkboxProps={{
              sx: {
                "& .MuiSvgIcon-root": {
                  fontSize: 26,
                },
              },
            }}
          >
            <span className={styles.title}>
              {EN_LOCALE.messages.preferencesVisibilityOption}
            </span>
            <span className={styles.description}>
              {EN_LOCALE.messages.preferencesVisibilityDescription}
            </span>
          </FormCheckbox>
        </>
      )}
    </form>
  );
};
