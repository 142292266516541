import {IAddress} from "types/customer";

export const formatServiceTarget = (
  address: Partial<Pick<IAddress, "postalCode" | "lat" | "lng">> | null
) => {
  if (!address) {
    return {};
  }

  const lat =
    address.lat && !Number.isNaN(Number(address.lat)) ? Number(address.lat) : undefined;
  const lng =
    address.lng && !Number.isNaN(Number(address.lng)) ? Number(address.lng) : undefined;

  return {
    lat,
    lng,
    zipCode: address.postalCode,
  };
};

export type ServiceTarget = ReturnType<typeof formatServiceTarget>;
