import {useCallback} from "react";
import useCustomerState from "hooks/useCustomerState";
import {useAppSelector} from "state/redux/hooks";
import {type ICreateCustomerResponse} from "state/redux/slices/auth/thunks/createCustomer";
import {type IVerifyOTPResponse} from "state/redux/slices/auth/thunks/verifyOtp";
import {getCentsCustomer} from "state/redux/slices/customer/selectors/credentialsSelector";

export const useAuthorization = () => {
  const customer = useAppSelector(getCentsCustomer);
  const isAuthorized = Boolean(customer?.id);

  const {setCustomer, setCustomerAuthToken} = useCustomerState();
  const authorizeUser = useCallback(
    (otpValidationResponse: IVerifyOTPResponse | ICreateCustomerResponse) => {
      setCustomerAuthToken(otpValidationResponse.customerAuthToken);
      setCustomer(otpValidationResponse.customer);
    },
    [setCustomer, setCustomerAuthToken]
  );

  return {customer, isAuthorized, authorizeUser};
};
