import {FC} from "react";
import classNames from "classnames";
import {useFlags} from "launchdarkly-react-client-sdk";
import {A11yButton} from "components/common/buttons/A11yButton";
import {SectionActionButton} from "components/common/buttons/SectionActionButton";
import {TransportationData} from "components/common/sectionItems/TransportationSection/TransportationSection";
import {ProviderLabel} from "components/common/sectionItems/TransportationSection/TransportationSectionItemButton/ProviderLabel";
import {TimeFrameLabel} from "components/common/sectionItems/TransportationSection/TransportationSectionItemButton/TimeFrameLabel";
import {OpenSchedulingDrawer} from "components/orderManager/tuneUp/OrderEditor/OrderEditor";
import {DELIVERY_PROVIDERS, ORDER_DELIVERY_TYPES} from "constants/order";
import {EN_LOCALE} from "locales/en";
import {EditIcon} from "assets/images";
import {ObjectValues} from "types/typeUtils";
import styles from "./TransportationSectionItemButton.module.scss";

export const TransportationSectionItemButton: FC<{
  type: string;
  onClick: OpenSchedulingDrawer;
  configuration: {
    date: TransportationData["formattedWindow"]["displayDate"];
    startTime: TransportationData["formattedWindow"]["startTime"];
    endTime: TransportationData["formattedWindow"]["endTime"];
  } | null;
  onDemandProviderAssets: {
    providerName: string;
    providerLogo: string;
  } | null;
  price: string | null;
  provider: ObjectValues<typeof DELIVERY_PROVIDERS> | null;
  turnAround: number | null;
  disabled?: boolean;
}> = ({
  type,
  configuration,
  onDemandProviderAssets,
  price,
  provider,
  turnAround,
  disabled,
  onClick,
}) => {
  const {manageOrderV2} = useFlags();

  return (
    <A11yButton
      className={styles.transportationContent}
      onAction={() =>
        onClick(
          type === EN_LOCALE.label.delivery
            ? ORDER_DELIVERY_TYPES.return
            : ORDER_DELIVERY_TYPES.pickup
        )
      }
    >
      <div>
        <h3
          className={classNames(styles.title, {
            [styles.tuneUp]: manageOrderV2,
          })}
        >
          {type} {EN_LOCALE.label.details}
        </h3>
        <TimeFrameLabel
          configuration={!!configuration?.date ? configuration : null}
          turnAround={turnAround}
        />
      </div>
      <div
        className={classNames(styles.assets, {
          [styles.tuneUp]: manageOrderV2,
        })}
      >
        {!disabled &&
          (manageOrderV2 ? (
            <SectionActionButton variant="primary" />
          ) : (
            <img src={EditIcon} alt={EN_LOCALE.label.pencil} width={16} height={16} />
          ))}
        {!!provider && (
          <ProviderLabel
            provider={provider}
            price={price}
            onDemandProviderAssets={onDemandProviderAssets}
          />
        )}
      </div>
    </A11yButton>
  );
};
