import {useMemo} from "react";
import PropTypes from "prop-types";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {Section} from "components/common/layouts/Section";
import {AddressDetails} from "components/common/sectionItems/AddressDetails";
import {AddressIndicator} from "components/common/sectionItems/AddressSectionItem/AddressIndicator";
import {TransportationSectionItemButton} from "components/common/sectionItems/TransportationSection/TransportationSectionItemButton";
import {EditCustomerAddress} from "components/newOrder/routes/checkout/online-order-form/finishing-up/delivery-details/EditCustomerAddress";
import {formatAddress} from "components/online-order/utils.js";
import useToggle from "hooks/useToggle";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {customerActions} from "state/redux/slices/customer";
import {patchAddress} from "state/redux/slices/customer/thunks/patchAddress";
import {orderActions, orderSelectors} from "state/redux/slices/order";
import {DELIVERY_TYPES} from "constants/deliveries/deliveryTypes";
import {VIEWS} from "constants/order";
import {EN_LOCALE} from "locales/en";
import {FetchingStatus} from "types/common";

export const DeliveryDetails = ({businessId}) => {
  const {url} = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {isOpen: showEditCustomerAddress, toggle: toggleShowEditCustomerAddress} =
    useToggle();
  const {
    currentCustomerAddress: address,
    schedule: {turnAround, pickup, returnInfo},
    nearStoresData: {
      data: {onDemandIsAvailable, ownDeliveryIsAvailable},
      fetchingStatus,
    },
  } = useAppSelector(orderSelectors.getOnlineOrderData);

  const isTransportationUpdateAvailable =
    fetchingStatus !== FetchingStatus.Fulfilled ||
    onDemandIsAvailable ||
    ownDeliveryIsAvailable;

  const formattedAddress = useMemo(() => {
    return formatAddress(address);
  }, [address]);

  const onRescheduleClick = (type) => {
    const urlToReplace = `schedule/${type.toLowerCase()}`;
    dispatch(
      orderActions.setStage(
        type === DELIVERY_TYPES.pickup
          ? VIEWS.ALL_WINDOWS_PICKUP
          : VIEWS.ALL_WINDOWS_RETURN
      )
    );
    history.push(url.replace("checkout", urlToReplace), location?.state);
  };

  const onAddressSubmit = async ({address2, instructions, leaveAtDoor}) => {
    const updatedAddress = {
      ...address,
      instructions,
      address2,
      leaveAtDoor,
    };

    await dispatch(
      patchAddress({
        googlePlacesId: address?.googlePlacesId,
        data: {
          address1: address?.address1,
          city: address?.city,
          postalCode: address?.postalCode,
          countryCode: address?.countryCode,
          instructions,
          address2,
          leaveAtDoor,
        },
      })
    );
    dispatch(
      customerActions.setSavedAddresses([
        {
          ...updatedAddress,
          googlePlacesId: address?.googlePlacesId,
        },
      ])
    );
    dispatch(orderActions.setIsAddressDetailsDrawerOpened(false));
  };

  return (
    <Section
      variant="primary"
      title={EN_LOCALE.label.pickupAndDelivery}
      separators={true}
    >
      <AddressIndicator
        address={formattedAddress}
        manageOrderV2={true}
        onClick={toggleShowEditCustomerAddress}
      />
      <TransportationSectionItemButton
        type={DELIVERY_TYPES.pickup}
        configuration={{
          date: pickup?.display?.displayDate,
          startTime: pickup?.display?.startTime,
          endTime: pickup?.display?.endTime,
        }}
        onDemandProviderAssets={pickup?.estimate || null}
        price={pickup?.display?.price || null}
        provider={pickup?.type || null}
        turnAround={turnAround}
        disabled={!isTransportationUpdateAvailable}
        onClick={() => onRescheduleClick(DELIVERY_TYPES.pickup)}
      />
      <TransportationSectionItemButton
        type={DELIVERY_TYPES.delivery}
        configuration={{
          date: returnInfo?.display?.displayDate,
          startTime: returnInfo?.display?.startTime,
          endTime: returnInfo?.display?.endTime,
        }}
        onDemandProviderAssets={returnInfo?.estimate || null}
        price={returnInfo?.display?.price || null}
        provider={returnInfo?.type || null}
        turnAround={turnAround}
        disabled={!isTransportationUpdateAvailable}
        onClick={() => onRescheduleClick(DELIVERY_TYPES.delivery)}
      />
      <AddressDetails
        addressId={address?.id}
        addressDetails={address?.address2}
        instructions={address?.instructions}
        onSubmit={onAddressSubmit}
        isResetTransportationEnabled={false}
      />
      <EditCustomerAddress
        isOpen={showEditCustomerAddress}
        toggle={toggleShowEditCustomerAddress}
        businessId={businessId}
      />
    </Section>
  );
};

DeliveryDetails.propTypes = {
  businessId: PropTypes.string,
};
